<template>
  <div class="jiaoyu_hejibig">
    <div class="jiaoyu_heji">
      <div class="jiaoyu_hejitop">
        <div class="jiaoyu_hejitopimg">
          <img :src="jiaoyu_hejiall.title_pic" alt="" />
        </div>
        <div class="jiaoyu_hejitopcenterbox">
          <p>{{ jiaoyu_hejiall.title }}</p>
          <p>{{ jiaoyu_hejiall.drawtext }}</p>
          <div>
            <img :src="jiaoyu_hejiall.userpic" alt="" />
            <span>{{ jiaoyu_hejiall.username }}</span>
            <span v-if="usergztype" @click="gztypeclick">{{
              gztype == "0" ? "关注" : "已关注"
            }}</span>
            <icon-svg icon-class="iconlook" />
            <span>{{
              jiaoyu_hejiall.onclick == null ? "0" : jiaoyu_hejiall.onclick
            }}</span>
            <icon-svg icon-class="icongood" />
            <span>{{
              jiaoyu_hejiall.dznum == null ? "0" : jiaoyu_hejiall.dznum
            }}</span>
            <icon-svg icon-class="iconvedio" />
            <span>{{
              jiaoyu_hejiall.video_num == null ? "0" : jiaoyu_hejiall.video_num
            }}</span>
          </div>
        </div>
        <div class="jiaoyu_hejitopright">
          <p>
            {{
              jiaoyu_hejiall.money == 0 ? "免费" : "￥" + jiaoyu_hejiall.money
            }}
          </p>
          <div @click="addheji">
            {{ jiarutype == 0 ? "立即加入" : "已加入" }}
          </div>
          <p class="jiaoyuhejijiarup">
            已有<span>{{
              jiaoyu_hejiall.join_atlas == null
                ? "0"
                : jiaoyu_hejiall.join_atlas
            }}</span
            >人加入
          </p>
        </div>
      </div>
      <ul class="jiaoyu_ul">
        <li
          class="jiaoyu_ulli"
          v-for="(item, index) in jiaoyu_ul"
          :key="index"
          :class="item.title ? '' : 'Zhanping_bisheullizero'"
          @click="jiaoyu_hejivedio(item.id)"
        >
          <div class="jiaoyu_ulliimg">
            <img v-lazy="item.titlepic" :alt="item.title" />
            <!-- <div class="jiaoyu_ulliimg_type">{{ item.videotype }}</div> -->
          </div>
          <div class="jiaoyu_ullititle">{{ item.title }}</div>
          <div class="jiaoyu_ullicenter">
            <img v-lazy="item.userpic" alt="" />
            <span>{{ item.username }}</span>
          </div>
          <div class="jiaoyu_ullibtm">
            <div class="jiaoyu_ullibtm_rd">
              <icon-svg icon-class="iconlook" />{{ item.onclick }}
            </div>
            <div class="jiaoyu_ullibtm_zan">
              <icon-svg icon-class="icongood" />{{ item.dznum }}
            </div>
            <div>{{ item.create_time }}</div>
          </div>
        </li>
        <li class="lizero" v-if="lizero">
          <img
            src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
            alt=""
          />
        </li>
      </ul>
      <el-pagination
        :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <zhifu :all="dataall" />
  </div>
</template>
<script>
import zhifu from "../components/zhifu";
export default {
  components: {
    zhifu,
  },
  data() {
    return {
      lizero: false,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      jiaoyu_ul: [],
      p_id: "",
      pagesize: 8,
      jiaoyu_hejiall: {},
      gztype: "",
      userinfo: {},
      member_id: "",
      total: 0,
      jiarutype: 0,
      dataall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "7",
        p_id: 0,
      },
      usergztype: false,
    };
  },
  methods: {
    currentchange(index) {
      this.hejiaxios(index);
    },
    jiaoyu_hejivedio(id) {
      var that = this;
      if (this.jiarutype == 0) {
        that.$message({
          message: "加入视频合集后可查看",
          duration: 1000,
        });
      } else {
        this.$router.push({
          path: "/Jiaoyu_c",
          query: { data: id, p_id: that.p_id },
        });
      }
    },
    addheji() {
      var that = this;
      if (that.userinfo == null) {
        this.$router.push({
          path: "/sjlogin",
        });
      } else {
        if (that.jiaoyu_hejiall.money == 0) {
          this.$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/getJoinPoster",
            method: "post",
            data: {
              id: that.p_id,
              userid: that.userinfo.userid,
            },
          }).then((res) => {
            if (res.data.code == 1) {
              that.$message({
                message: "加入成功",
                duration: 2000,
                type: "success",
              });
              location.reload();
            } else {
              that.$message({
                message: "不可重复加入",
                duration: 2000,
                type: 'error',
              });
            }
          });
        } else {
          if (this.jiarutype == 0) {
            this.$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/payVideo",
              method: "post",
              data: {
                id: that.jiaoyu_hejiall.p_id,
                money: that.jiaoyu_hejiall.money,
                title: that.jiaoyu_hejiall.title,
              },
            }).then((res) => {
              if (res.data.code == 1) {
                that.dataall = {
                  type: true,
                  money: that.jiaoyu_hejiall.money,
                  ddnnum: res.data.result,
                  class_id: "7",
                  p_id: that.p_id,
                };
              } else {
              }
            });
          } else {
            that.$message({
              message: "不可重复加入",
              duration: 1000,
            });
          }
        }
      }
    },
    gztypeclick() {
      var that = this;
      if (that.userinfo != null) {
        var gztypett = "";
        if (this.gztype == "1") {
          gztypett = "getCancelFollow";
        } else {
          gztypett = "getFollow";
        }
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/" + gztypett + "",
          method: "post",
          data: {
            userid: that.userinfo.userid,
            member_id: that.member_id,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            if (that.gztype == 0) {
              that.gztype = 1;
              that.$message({
                message: "添加关注成功",
                type: "success",
                duration: 1000,
              });
            } else {
              that.gztype = 0;
              that.$message({
                message: "取消关注成功",
                type: "success",
                duration: 1000,
              });
            }
            this.$forceUpdate();
          } else {
          }
        });
      } else {
        this.$router.push({
          path: "/sjlogin",
        });
      }
    },
    hejiaxios(page) {
      var that = this;
      this.lizero = true;
      that.jiaoyu_ul = [];
      this.$axios({
        url: "/api/getVideoList",
        method: "post",
        data: {
          p_id: that.p_id,
          page,
          limit: that.pagesize,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.lizero = false;
          that.jiaoyu_ul = res.data.result.list;
          that.total = res.data.result.count;
          var count = res.data.result.list.length % 4;
          var li = "";
          if (count == 2) {
            that.jiaoyu_ul.push(li, li);
          } else if (count == 3) {
            that.jiaoyu_ul.push(li);
          }
        } else {
          this.lizero = true;
        }
      });
    },
    hejointype() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getMemberJoinVideo",
        method: "post",
        data: {
          id: that.p_id,
        },
      }).then((res) => {
        that.jiarutype = res.data.result;
      });
    },
  },
  created() {
    var that = this;
    that.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    var p_id = that.$route.query.data;
    that.p_id = p_id;
    this.hejiaxios(1);
    this.$axios({
      url: "/api/getPosterInfo",
      method: "post",
      data: {
        p_id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.jiaoyu_hejiall = res.data.result;
        document.title = res.data.result.title + " 视频合集列表";
        this.fenxiang(
          document.title,
          res.data.result.title,
         res.data.result.title_pic
        );

        that.member_id = res.data.result.userid;
        if (that.userinfo != null) {
          if (that.userinfo.userid == res.data.result.userid) {
            that.usergztype = false;
          } else {
            that.usergztype = true;
          }
          that
            .$axios({
              url: "/api/getFollowFans",
              method: "post",
              data: {
                userid: that.userinfo.userid,
                f_type: "1",
                page: "1",
                limit: "100000",
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                var fensi = res.data.result.list;
                for (var i = 0; i < fensi.length; i++) {
                  if (fensi[i].userid == that.member_id) {
                    that.gztype = "1";
                    return;
                  } else {
                    that.gztype = "0";
                  }
                }
              } else {
              }
            });
        } else {
          that.gztype = "0";
          that.usergztype = true;
        }
      } else {
      }
    });
    ////////////////
    if (that.userinfo != null) {
      this.hejointype();
    }
  },
};
</script>
<style>
@import "../assets/css/jiaoyu.css";
</style>